.project-container {
    margin-bottom: 85px;
  }

  .project-header {
    font-size: 2em;
    padding: .5em;
  }
  .carousel-root {
    max-width: 800px;
    padding: 1vw;
    margin: 0 auto;
  }

  .temp {
    filter:blur(8px);
  }

  .project-link {
    padding-top: 150px;
  }


  
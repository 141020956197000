.active {
    background-color: #1d4ed8;
}

.close-menu-icon {
    padding: .25rem;
    margin: .25rem;
    display: none;
    border-radius: .25em;
}

.contact-container-nav {
    display: flex;
}

.contact-icon {
    display: flex;
}

.contact-icon{
    color: white;
    font-size: 1.5rem;
}

.menu-controls {
    display: none;
}

.nav-button {
  text-decoration: none;
  font-size: 1.25rem;
  display: block;
  color: white;
  border-radius: 0.5rem;
  width: 75px;
  text-align: center;
  padding: .5rem;
  }

.nav-container {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
}

.nav-headers {
    font-size: 25px;
    color: white;
}

.nav-item {
    display: flex;
    margin: .25rem;
    padding: .25rem;
    list-style: none;
}

.nav-main {
    background-color: #101217;
    height: 75px;
    display: flex;
    justify-content: center;
}

.open-menu-icon {
    padding: .25rem;
    display: none;
    border-radius: .25em;
}

nav ul li a:not(.active):hover {
    background-color: #1d4fd88e
}

@media (max-width: 700px) {
    .nav-container {
        flex-direction: column;
    }
    .nav-item {
        padding: .1rem;
        margin: .1rem;
    }
    .nav-button{
        font-size: 1rem;
        padding: .25rem;
    }
    .nav-main {
        height: auto;
    }
    .open-menu-icon {
        display: block;
    }
    .open-menu-icon:hover {
        cursor: pointer;
        background-color: #1d4fd88e;
    }
    .close-menu-icon {
        display: block;
    }
    .close-menu-icon:hover {
        cursor: pointer;
        background-color: #1d4fd88e;;
    }
}
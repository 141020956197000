 form {
  display: flex;
  flex-direction: column;
  border: 5px groove purple;
  padding: .25rem;
  border-radius: .5em;
  background-color: rgba(255, 255, 255, 0.632);
 } 

 .contact-container {
  margin: 25px 0px 75px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
 }
 .contact-header {
  text-align: center;
 }
  
  .contact-field {
    margin: .3em;
  }

  .contact-field button {
    width: 150px;
    font-size: 1.25em;
    color: rgba(255, 255, 255, 0.555);
    border-radius: .5rem;
    padding: .5rem;
    background-color: #1d4ed8;
  }

  .contact-field button:hover {
    font-size: 1.25em;
    color: white;
    border-radius: .5rem;
    padding: .5rem;
    background-color: green;
    cursor: pointer;
  }

  #email {
    height: 50px;
    width: 350px;
    border: 2px solid black;
    border-radius: .5em;
    text-align: center;
  }

  #message {
    width: 350px;
    height: 300px;
    border: 2px solid black;
    border-radius: .5em;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .send-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow-y: auto;

  }

  .social-item {
    cursor: pointer;
    margin: .1em;
    padding: .2em;
    border-radius: .25em;
  }

  .social-item a {
    color: black;
  }

  .social-item:hover {
    background-color: #1d4fd88e;
  }

  .success-message {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 2.5em;
  }

  .success-message div {
    display:flex;
    width: 350px;
    border: 5px groove purple;
    border-radius: .25em;
    padding: 1em;
    margin: .2em;
  }

  @media (max-width: 475px) {
    .contact-field {
      margin: .15em;
      padding: .25em;
    }
    .contact-header {
      font-size: .75em;
    }
    #message {
      width: 275px;
      height: 200px;
    }
    #email {
      width: 275px;
      height: 50px;
    }
  }
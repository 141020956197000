.contact-icon {
    display: block;
    cursor: pointer;
    border-radius: .25em;
    padding: .2em;
    margin: .1em;
}

.contact-icon:hover {
    background-color: #1d4fd88e
}

.contact-icon-container {
    justify-content: center;
    align-items: center;
    margin-top: auto;
}

.contact-module {
    display: flex;
}
